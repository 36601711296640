<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col
          v-if="permisos.includes('nom_marca_asociada_hotels:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="text-right"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('menu.marcaAsociada') | lowercase }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th width="10%">
              LOGO
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.name') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.operador') }}
            </th>
            <th
              v-if="
                permisos.includes('nom_marca_asociada_hotels:edit') ||
                  permisos.includes('nom_marca_asociada_hotels:destroy') ||
                  permisos.includes('*')
              "
              class="text-uppercase"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>
              <v-row>
                <v-col cols="6">
                  <v-avatar
                    v-if="iten.logo === null"
                    color="primary"
                    size="48"
                  >
                    <span class="white--text text-h5 text-uppercase">{{ iten.name.slice(0, 2) }}</span>
                  </v-avatar>
                  <v-avatar
                    v-else
                    class="profile"
                    size="60"
                    tile
                  >
                    <v-img
                      :src="rutaPublic + iten.logo"
                      :alt="iten.name"
                      contain
                    ></v-img>
                  </v-avatar>
                </v-col>
              </v-row>
            </td>
            <td>{{ getName(iten) }}</td>
            <td>
              <span v-if="iten.operador_id != null">
                <span
                  v-for="(ope, indP) in iten.operador"
                  :key="indP"
                >
                  {{ getNameOperador(ope) }}
                  <br /> </span>
              </span>
            </td>
            <td
              v-if="
                permisos.includes('nom_marca_asociada_hotels:edit') ||
                  permisos.includes('nom_marca_asociada_hotels:destroy') ||
                  permisos.includes('*')
              "
            >
              <v-tooltip
                v-if="permisos.includes('nom_marca_asociada_hotels:edit') || permisos.includes('*')"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(iten)"
                  >
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.edit') }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="permisos.includes('nom_marca_asociada_hotels:destroy') || permisos.includes('*')"
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItem(iten)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- MOSTRAR FORM -->
    <v-dialog
      v-model="isDialogVisible"
      scrollable
      max-width="450px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('menu.marcaAsociada') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
              class="mt-5"
            >
              <v-text-field
                v-model="item.name"
                :label="$t('lbl.name')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <!--<v-select
                v-model="item.operador_id"
                :items="operadors"
                item-text="name"
                item-value="id"
                :label="$t('menu.operador')"
                outlined
                dense
                hide-details
                multiple
              ></v-select>-->
              <v-autocomplete
                v-model="item.operador_id"
                :items="operadors"
                outlined
                dense
                chips
                small-chips
                item-text="name"
                item-value="id"
                :label="$t('menu.operador')"
                multiple
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-file-input
                v-model="logo"
                accept="image/*"
                show-size
                outlined
                dense
                label="Logo"
                hide-details
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisible = !isDialogVisible"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :loading="loading"
            @click="save"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${item.name}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p class="mt-5">
            {{ $t('msg.deleteItem', { n: item.name }) }}
          </p>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      items: [],
      totalItems: 0,
      totalF: 0,
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      item: {},
      operadors: [],
      logo: null,
      itemId: null,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
    }
  },
  created() {
    this.profile()
    this.fetchDataFromEndpoints()
  },
  methods: {
    getName(item) {
      if (this.$i18n.locale === 'es') {
        return item.name
      }

      return item[this.$i18n.locale]
    },
    getNameOperador(item) {
      if (this.$i18n.locale === 'es') {
        return item.name
      }

      return item[this.$i18n.locale]
    },
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
      if (this.user.nivel === -1) {
        this.is_nivel_propietario = true
      }

      this.isLoading = false
    },
    async fetchDataFromEndpoints() {
      try {
        const [
          resLoad,
          resAll,
          resOperadors,
        ] = await Promise.all([
          this.axios
            .get(
              `nom_marca_asociada_hotels?page=${this.pagination.current}&per_page=${this.itemsPerPage}&search=${
                this.search
              }&perfil_slug=${localStorage.getItem('perfil')}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              },
            ),
          this.axios
            .get('nom_marca_asociada_hotels?per_page=100000', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get('nom_operador_hotels?per_page=100000', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        // eslint-disable-next-line no-empty
        if (resLoad.data.success === false) { } else {
          this.items = resLoad.data.data
          this.pagination.current = resLoad.data.meta.current_page
          this.pagination.total = resLoad.data.meta.last_page

          this.totalF = resLoad.data.meta.total
        }

        // eslint-disable-next-line no-empty
        if (resAll.data.success === false) { } else {
          this.totalItems = resAll.data.meta.total
        }

        // eslint-disable-next-line no-empty
        if (resOperadors.data.success === false) { } else {
          this.operadors = resOperadors.data.data
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
    async load() {
      await this.axios
        .get(
          `nom_marca_asociada_hotels?page=${this.pagination.current}&per_page=${this.itemsPerPage}&search=${
            this.search
          }&perfil_slug=${localStorage.getItem('perfil')}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
    },
    async loadAll() {
      await this.axios
        .get('nom_marca_asociada_hotels?per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    onPageChange() {
      this.load()
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    newItem() {
      this.isDialogVisible = true
      this.item = {}
      this.itemId = null
    },
    editItem(item) {
      this.isDialogVisible = true
      this.item = item
      this.itemId = item.id
    },
    save() {
      if (this.item.name && this.item.operador_id) {
        this.loading = true
        this.item.perfil_slug = localStorage.getItem('perfil')
        const formData = new FormData()
        if (this.logo) {
          formData.append('logo', this.logo)
        }

        formData.append('data', JSON.stringify(this.item))

        if (this.itemId === null) {
          this.axios
            .post('nom_marca_asociada_hotels', formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.marcaAsociadaExist', { n: this.item.name }))
                }
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reseat())
        } else {
          this.axios
            .post(`nom_marca_asociada_hotels/actualice/${this.itemId}`, formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reseat())
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.axios
        .delete(`nom_marca_asociada_hotels/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.logo = null
      this.load()
      this.loadAll()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
